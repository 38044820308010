<template>
	<edit-template class="add" @confirm="submit" @cancel="$confirm('取消编辑?', '提示', { type: 'warning' }).then(res => $router.back())" :cancelRemind="false" confirmText="提交" cancelText="取消">

    <t-refund-card ref="base" title="基础信息" :model="formData" :rules="rules" v-if="!$route.query.id">
      <!--      <el-form ref="form" :model="formData" label-width="200rem" :rules="rules">-->
      <el-form-item label="报名学校" prop="school_id" required="">
        <el-select v-model="formData.school_id" :popper-append-to-body="false" placeholder="请选择报名学校" @change="changeSchool">
          <el-option v-for="item in selectConfig.school_data" :key="item.school_id" :label="item.school_name" :value="item.school_id">
        </el-option></el-select>
      </el-form-item>
      <el-form-item label="入学年级" prop="grade_name" required="">
        <el-select v-model="formData.grade_name" :popper-append-to-body="false" placeholder="请选择入学年级">
          <el-option v-for="(item,index) of selectConfig.grade_data" :key="index" :label="item" :value="item">
        </el-option></el-select>
      </el-form-item>
      <el-form-item label="入学年份" prop="grade_year" required="">
        <el-select v-model="formData.grade_year" :popper-append-to-body="false" placeholder="请选择入学年份">
          <el-option v-for="(item,index) of selectConfig.grade_year_data" :key="index" :label="item+''" :value="item+''">
        </el-option></el-select>
      </el-form-item>
      <el-form-item label="学生类型" prop="student_type" required="">
        <el-select v-model="formData.student_type" :popper-append-to-body="false" placeholder="请选择学生类型" @change="studentChange">
          <el-option v-for="item in selectConfig.student_type_add" :key="item.student_type" :label="item.student_type_name" :value="item.student_type">
        </el-option></el-select>
      </el-form-item>
      <el-form-item label="学生姓名" prop="student_name" required="">
        <el-input v-model="formData.student_name" maxlength="14" placeholder="请输入学生姓名"></el-input>
      </el-form-item>
      <el-form-item label="学生身份证号" prop="student_idcard" required="">
        <el-input v-model="formData.student_idcard" maxlength="18" placeholder="请输入学生身份证号"></el-input>
      </el-form-item>
      <el-form-item label="是否为培优生" prop="is_training" required="">
        <el-select v-model="formData.is_training" :popper-append-to-body="false" placeholder="请选择是否为培优生">
          <el-option label="否" :value="0">
          <el-option label="是" :value="1">
        </el-option></el-option></el-select>
      </el-form-item>
      <el-form-item label="学习方向" prop="study_type">
        <el-select v-model="formData.study_type" :popper-append-to-body="false" placeholder="请选择学习方向" @change="formData.major=undefined">
          <el-option v-for="(item,index) in studyType.study_type_data" :key="index" :label="item.study_type_value" :value="item.study_type">
        </el-option></el-select>
      </el-form-item>
      <!--   需求: 当学习方向为文化不展示专业方向   -->
      <el-form-item label="专业方向" prop="major" required="" v-if="formData.study_type&amp;&amp;formData.study_type!=1">
        <el-select v-model="formData.major" :popper-append-to-body="false" placeholder="请选择专业方向">
          <el-option v-for="(item,index) in major_data" :key="index" :label="item" :value="item">
        </el-option></el-select>
      </el-form-item>
      <el-form-item label="家长姓名">
        <el-input v-model="formData.parent_name" maxlength="14" placeholder="请输入家长姓名"></el-input>
      </el-form-item>
      <el-form-item label="家长手机号">
        <el-input v-model="formData.parent_phone" maxlength="11" placeholder="请输入家长手机号"></el-input>
      </el-form-item>
      <el-form-item :inline="true" label="家庭住址">
        <div style="display: flex">
          <el-form-item prop="family_province">
            <el-select v-model="formData.family_province" :popper-append-to-body="false" class="select" placeholder="请选择省" @change="changeProvince">
              <el-option v-for="(item, index) in province" :key="index" ref="prove" :label="item.address_name" :value="item.address_code"></el-option>
            </el-select>
          </el-form-item>
          -
          <el-form-item prop="family_city">
            <el-select v-model="formData.family_city" :popper-append-to-body="false" class="select" placeholder="请选择市" @change="changeCity">
              <el-option v-for="(item, index) in city" :key="index" ref="prove" :label="item.address_name" :value="item.address_code"></el-option>
            </el-select>
          </el-form-item>
          -
          <el-form-item prop="family_area">
            <el-select v-model="formData.family_area" :popper-append-to-body="false" class="select" placeholder="请选择区" @change="changeArea">
              <el-option v-for="(item, index) in area" :key="index" ref="prove" :label="item.address_name" :value="item.address_code"></el-option>
            </el-select>
          </el-form-item>
          -
          <el-form-item prop="family_addr">
            <el-input v-model="formData.family_addr" maxlength="30" placeholder="请输入详细地址"></el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="实缴金额" prop="paid_money" required="">
        <el-input v-model="formData.paid_money" placeholder="请输入实缴金额"></el-input>
      </el-form-item>
      <el-form-item label="缴费时间" prop="paid_time" required="">
        <el-date-picker v-model="formData.paid_time" placeholder="请选择缴费时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item label="住宿寝室" prop="goods_standard_id">
        <el-input v-model="formData.dormitory" placeholder="请输入住宿寝室"></el-input>
      </el-form-item>
      <el-form-item ref="img-box" label="缴费凭证">
        <upload-img v-model="formData.voucher_img">
      </upload-img></el-form-item>
    </t-refund-card>
    <t-refund-card ref="base" title="基础信息" :model="formData" :rules="rules" v-else="">
      <!--      <el-form ref="form" :model="formData" label-width="200rem" :rules="rules">-->
      <el-form-item label="报名学校">
        {{reDisplay.school_name}}
      </el-form-item>
      <el-form-item label="入学年级">
        {{reDisplay.grade_name}}
      </el-form-item>
      <el-form-item label="入学年份">
        {{reDisplay.enrollment_year}}
      </el-form-item>
      <el-form-item label="学生类型">
        {{reDisplay.student_type}}
      </el-form-item>
      <el-form-item label="学生姓名">
        {{reDisplay.student_name}}
      </el-form-item>
      <el-form-item label="学生身份证号">
        {{reDisplay.student_idcard}}
      </el-form-item>
      <el-form-item label="是否为培优生">
        {{reDisplay.is_training}}
      </el-form-item>
      <el-form-item label="学习方向">
        {{reDisplay.study_type}}
      </el-form-item>
      <!--   需求: 当学习方向为文化不展示专业方向   -->
      <el-form-item label="专业方向" v-if="formData.study_type&amp;&amp;formData.study_type!=1">
        {{reDisplay.major}}
      </el-form-item>
      <el-form-item label="家长姓名">
        {{reDisplay.parent_name}}
      </el-form-item>
      <el-form-item label="家长手机号">
        {{reDisplay.parent_phone}}
      </el-form-item>
      <el-form-item :inline="true" label="家庭住址">
        {{reDisplay.family_addr_describe}}
      </el-form-item>
      <el-form-item label="实缴金额">
        {{reDisplay.paid_money}}
      </el-form-item>
      <el-form-item label="缴费时间">
        {{reDisplay.paid_time}}
      </el-form-item>
      <el-form-item label="住宿寝室" prop="goods_standard_id">
        {{reDisplay.dormitory}}
      </el-form-item>
      <el-form-item ref="img-box" label="缴费凭证" v-if="!!reDisplay.voucher_img">
        <el-image :src="reDisplay.voucher_img" :preview-src-list="[reDisplay.voucher_img]">
      </el-image></el-form-item>
    </t-refund-card>
    <t-refund-edit v-model="formData" ref="refund"></t-refund-edit>


</edit-template>
</template>
<script>
import uploadImg from '@/components/common/upload.vue'
import TRefundCard from "../components/RefundCard"
import TRefundEdit from "../components/RefundEdit"
import {getDetails} from "./Details";

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增","style":0}}},
  components: {uploadImg, TRefundCard,TRefundEdit},
  data() {
    const majorRule = (rule,value,callback)=>{
      if (this.formData.study_type!=null && this.formData.study_type !== 1){
        if(value == null || value === "")callback(new Error("请选择专业方向"))
        else callback()
      }else callback()
    }
    return {
      titleStyle: {
        borderBottom: '1rem solid #eee',
        paddingBottom: '10rem',
        display: 'block'
      },
      loadingBtn:false,
      // 选择框配置
      selectConfig: {},
      student_type: [
        {required: true, message: '请选择学生类型', trigger: 'change'}
      ],
      // 学习方向专业方向
      studyType: {
        study_type_data: [],
        major_data: [],
      },
      // 寝室
      dormitory: [],
      reDisplay:{},
      // 表单数据
      formData: {
        family_province:null,
        major:undefined,
        family_city:null,
        family_area:null,
        family_addr:null,
      },
      headers: {
        authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')
      },
      dialogVisible: false,
      dialogVisibleUrl: '',
      // 表单验证规则
      rules: {
        school_id: [{required: true, message: '请选择报名学校', trigger: 'change'}],
        grade_name: [{required: true, message: '请选择入学年级', trigger: 'change'}],
        grade_year: [{required: true, message: '请选择入学年份', trigger: 'change'}],
        student_type: [{required: true, message: '请选择学生类型', trigger: 'change'}],
        student_name: [{required: true, message: '请输入学生姓名', trigger: 'change'}],
        student_idcard: [{required: true, message: '请输入学生身份证号', trigger: 'change'}],
        is_training: [{required: true, message: '请选择是否为培优生', trigger: 'change'}],
        major: [{validator:majorRule, trigger: 'change'}],
        // study_type: [{required: true, message: '请选择学习方向', trigger: 'change'}],
        paid_money: [{required: true, message: '请输入实缴金额', trigger: 'change'}],
        paid_time: [{required: true, message: '请选择缴费时间', trigger: 'change'}],
      },
      province:[],
      city:[],
      area:[],
    }
  },
  computed:{

    major_data: function () {
        if (!this.formData.study_type) return []
        return this.studyType.major_data.find(item => item.id === this.formData.study_type).major_type_value
      },
    },
  created() {
    if(this.$route.query.id == null) {
      this.$_register.get('api/recruit-v2/offline/config').then(res => {
        this.selectConfig = res.data.data
      })
      this.$_register('/api/recruit/common/area', {params: {address_code: '000000'}}).then(res => {
        this.province = res.data.data
      })
      this.$_register('api/recruit-v2/rereading/screen').then(res => {
        this.studyType = res.data.data
      })
    }else{
      getDetails(this.$route.query.id).then(res=>{
        let data = res.data.data;
        let {
          id,
          paid_money,
          money,
          refund_offline_id,
          refund_day,
          refund_status,
          refund_type,
          bank_card_number,
          opening_bank,
          account_holder,
          refund_transactors_id,
          describe,
          refund_img,
          refund_transactors_name,
        } = data;
        this.reDisplay = data;
        this.formData = {
          id,
          paid_money,
          money,
          refund_offline_id,
          refund_day,
          refund_status,
          refund_type,
          bank_card_number,
          opening_bank,
          account_holder,
          refund_transactors_id,
          describe,
          refund_img,
        }
        this.$refs.refund.refund_transactors = [{account_id:refund_transactors_id,name:refund_transactors_name}]
      }).finally(()=>this.loading=false)
    }
  },
  methods:{
    log:console.log,
    // 选择宿舍
    changeGoodsStandardId(val) {
      this.formData.standard_name = this.dormitory.find(item => item.goods_standard_id === val).standard_name
    },
    sendData(url){
      this.$_register.post(url,this.formData).then(res=>{
        if(this.$route.query.id != null){
          return this.$router.back()
        }
        this.$router.replace("./details?id="+res.data.data.id)
        this.$store.commit('setPage', 1)
      }).finally(()=>this.loadingBtn = false)
    },
    submit(){
      let flag = true;
      this.$refs.base.validate((res) =>{
        flag = res;
        this.$refs.refund.validate((res)=>{
          if (flag){
            this.loadingBtn = true
            // 新增
            let url = "api/refund/offline-information/add"
            // 变更
            if(this.$route.query.id != null){
              url = "api/refund/offline-information/edit"
              this.$confirm("是否确认更改退费详情?",{
                cancelButtonText:"点错了",
                confirmButtonText:"确认",
              }).then(()=>{
                this.sendData(url)
              })
            }else this.sendData(url)          }
        })
      })
    },
    changeProvince(val) {
      this.city = []
      this.area = []
      this.$_register.get('/api/recruit/common/area', {params: {address_code: val}}).then(res => {
        this.city = res.data.data
        this.formData.city = ''
        this.formData.area = ''
        this.formData.address = ''
      })
    },
//图片上传前的回调
    imgUploadBefore(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isJPG) {
        this.$message({
          type: 'error',
          message: '上传图片只能是 jpg/png 格式!',
          duration: 2000
        })
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '上传图片大小不能超过 20MB!',
          duration: 2000
        })
      }
      return isJPG && isLt2M
    },

    //删除图片回调
    imgUploadRemove(file, fileList) {
      this.formData.payment_voucher = fileList
    },

    //点击图片时的回调
    imgPreview(file) {
      this.dialogVisible = true
      this.dialogVisibleUrl = file.url
    },

    uploadImg(file) {
      let boo = this.imgUploadBefore(file.file)
      if (boo) {
        const loading = this.$loading({
          lock: true,
          text: '上传中',
          customClass: 'img-upload-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$uploadToOSS_1(file.file, 3).then(res => {
          this.formData.payment_voucher.push({
            name: file.file.name,
            url: res
          })
        }).finally(() => {
          loading.close()
        })
      }
    },

    btnClick() {
      this.$refs.imgInput.value = ''
      this.$refs.imgInput.click()
    },
    addImg(e) {
      let list = e.target.files
      let count = 5 - (this.formData.payment_voucher.length || 0)
      Object.keys(list).forEach(async (item, index) => {
        if (index < count) {
          this.uploadImg({file: list[item]})
        }
      })
    },
    studentChange(val) {
      switch (val) {
        case 1:
          this.formData.grade_name = this.selectConfig.grade_data[0]
          break
        case 2:
          this.formData.grade_name = this.selectConfig.grade_data[1]
          this.dormitory.forEach(item => {
            if (item.standard_name === '8人间') {
              this.formData.goods_standard_id = item.goods_standard_id
            }
          })
          break
      }
    },
    // 选择校区
    changeSchool(val) {
      // this.$_register.get('api/recruit-v2/common/goods-standard', {params: {school_id: val}}).then(res => {
      //   this.dormitory = res.data.data
      //   this.formData.goods_standard_id = ''
      //   this.formData.standard_name = ''
      // })
    },
    changeCity(val) {
      this.area = []
      this.$_register.get('/api/recruit/common/area', {params: {address_code: val}}).then(res => {
        this.area = res.data.data
        this.formData.area = ''
        this.formData.address = ''
      })
    },

    changeArea(val) {
      this.formData.address = ''
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  display: none;
}

::v-deep .el-form .el-input {
  width: 250rem !important;
}

::v-deep .el-form-item__label {
  padding-right: 40rem !important;
}

::v-deep .yyy .el-form-item__label {
  line-height: 72rem !important;
}

::v-deep .el-form .yyy2 .el-input {
  width: 150rem !important;
}
.add{
  background: transparent !important;
}
</style>
