<template>
<div class="details">
  <t-refund-card title="基础信息">
    <!--      <el-form ref="form" :model="formData" label-width="200rem" :rules="rules">-->
    <el-form-item label="报名学校"  >
      {{ data.school_name }}
    </el-form-item>
    <el-form-item label="入学年级"  >
      {{ data.grade_name }}
    </el-form-item>
    <el-form-item label="入学年份"  >
      {{ data.enrollment_year }}
    </el-form-item>
    <el-form-item label="学生类型"  >
      {{ data.student_type_text }}
    </el-form-item>
    <el-form-item label="学生姓名"  >
      {{ data.student_name }}
    </el-form-item>
    <el-form-item label="学生身份证号"  >
      {{ data.student_idcard }}
    </el-form-item>
    <el-form-item label="是否为培优生"  >
      {{ data.is_training_text }}
    </el-form-item>
    <el-form-item label="学习方向" >
      {{ data.study_type_text }}
    </el-form-item>
    <el-form-item label="专业方向" v-if="data.study_type !== 1">
      {{ data.major }}
    </el-form-item>
    <el-form-item label="家长姓名" >
      {{ data.parent_name }}
    </el-form-item>
    <el-form-item label="家长手机号" >
      {{ data.parent_phone }}
    </el-form-item>
    <el-form-item :inline="true" label="家庭住址">
      {{ data.family_addr_describe }}
    </el-form-item>

    <el-form-item label="实缴金额"  >
     {{ data.paid_money }}
    </el-form-item>
    <el-form-item label="缴费时间"  >
      {{ data.paid_time }}
    </el-form-item>
    <el-form-item label="住宿寝室" >
      {{ data.dormitory }}
    </el-form-item>
    <el-form-item ref="img-box" v-if="!!data.voucher_img" label="缴费凭证">
      <el-image :src="data.voucher_img" :preview-src-list="[data.voucher_img]" />
    </el-form-item>
  </t-refund-card>

  <t-refund-edit :value="data" is-show />

  <t-refund-card title="操作记录">
    <el-form class="content" label-width="130rem">
      <el-form-item label="录入人：">
        <span>{{ data.creater_name }}</span>
      </el-form-item>
      <el-form-item label="录入时间：">
        <span>{{ data.created_at }}</span>
      </el-form-item>
    </el-form>

  </t-refund-card>
  <div class="footer-button">
    <el-button @click="$router.back()">返回</el-button>
    <el-button @click="$router.push('./add?id='+$route.query.id)">变更</el-button>
    <el-button @click="record">变更记录</el-button>
  </div>
  <t-dialog :visible="timeline" @close="timeline=false">
    <div class="timeline" v-for="item in recordData">
      <div class="left"></div>
      <div class="right">
        {{item}}
      </div>
    </div>
    <t-result v-if="!recordData.length" />
  </t-dialog>
</div>
</template>
<script>
import TRefundCard from "../components/RefundCard"
import TRefundEdit from "../components/RefundEdit"
import TDialog from "@/components/Dialog.vue"
import axios2 from "../../../../utils/axios.register";
let loading;
// 获取未补录退费详情
export function getDetails(id){
  return axios2.get("/api/refund/offline-information/details?id="+id)
}
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看","keepAlive":true}}},
  name: "Details",
  components:{TRefundCard,TRefundEdit,TDialog},
  data(){
    return{
      data:{},
      timeline:false,
      loading:false,
      recordData:[]
    }
  },
  watch:{
    loading(val){
      if (val){
        loading = this.$loading({
          txt:"请稍后"
        })
      }else{
        loading.close()
      }
    }
  },
  created() {
    this.getData();
  },
  activated() {
    this.getData();
  },
  methods:{
    getData(){
      this.loading = true;
      getDetails(this.$route.query.id).then(res=>this.data = res.data.data).finally(()=>this.loading=false)
    },
    record(){
      this.loading = true;
      this.$_register.get("/api/refund/offline-PC/refund-opt-history",{
        params:{
          id:this.data.refund_offline_id
        }
      }).then(res=>{
        this.timeline = true
        this.recordData = res.data.data
      }).finally(()=>this.loading=false)
    }
  }
}
</script>
<style lang="scss" scoped>

.details{
  background: transparent !important;
}
.timeline{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20rem;
  .left{
    border-radius: 50%;
    background: #1a1d7a;
    width: 15rem;
    height:15rem;
  }
  .right{
    flex:1;
    margin-left: 20rem;
  }
}
.timeline:last-child{
  margin-bottom: 0;
}
.footer-button{
  background: white;
  border: none;
  line-height: 80rem;
}
</style>
